
import {defineComponent, onMounted, ref} from "vue";
import BaseSelect from "@/components/base/select/BaseSelect.vue";
import Entities from "@/components/base/common/Entities.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import {ClientSelect, UserSelect} from "@/core/composite/composite";
import TimerService from "@/core/services/TimerService";
import Duration from "@/views/timer/Duration.vue";
import HighChart from "@/components/base/chart/HighChart.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";

export default defineComponent({
  name: "Overview",
  components: {EntityLoading, HighChart, Duration, Entities, BaseSelect},
  setup() {
    const state = ref({total: 0, chart: {}, loading: true});

    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Track",
        [
          {link: false, router: '', text: 'Overview'}
        ]
      )
    })
    const filter = ref({type: 'THIS_WEEK', userId: '', client: ''})
    const loadTotal = () => {
      TimerService.total(filter.value).then(res => {
        state.value.total = res.total
      })
    }
    const loadChart = () => {
      state.value.loading = true;
      TimerService.chart(filter.value).then(res => {
        state.value.chart = res;
      }).finally(() => {
        state.value.loading = false;
      })
    }
    loadChart();
    const discardEvent = () => {
      filter.value = {type: 'THIS_WEEK', userId: '', client: ''};
      loadTotal()
      loadChart()
    }
    const searchEvent = () => {
      loadChart();
      loadTotal()
    }
    loadTotal()
    return {
      filter,
      discardEvent,
      searchEvent,
      ...UserSelect(),
      ...ClientSelect(),
      state,
    }
  }
})
